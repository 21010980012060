import React, { useState } from 'react';
import { Box, Typography, Button, Modal, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { db } from '../firebase'; // Ensure this import path is correct
import { collection, addDoc } from 'firebase/firestore';

const AboutUs = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    contactNumber: ''
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation checks
    if (formData.name.trim() === '' || formData.contactNumber.trim() === '') {
      alert('Please provide a valid name and contact number.');
      return;
    }

    if (!/^\d{10}$/.test(formData.contactNumber)) { // Simple regex to check if the contact number is a 10-digit number
      alert('Please provide a valid 10-digit contact number.');
      return;
    }

    try {
      await addDoc(collection(db, 'Request_a_call_back'), formData);
      alert('Your request has been submitted successfully!');
      setFormData({
        name: '',
        contactNumber: ''
      });
      handleClose();
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Error submitting your request. Please try again.');
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    bgcolor: 'background.paper',
    border: '2px solid #00418D',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  };

  const closeIconStyle = {
    position: 'absolute',
    top: 8,
    right: 8,
  };

  return (
    <Box id="about-us" sx={{ padding: '2rem', backgroundColor: 'white', textAlign: 'center' }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '30px',
          color: '#00418D',
          '@media (max-width: 768px)': {
            fontSize: '28px',
          },
        }}
      >
        About Us
      </Typography>
      
      <Box sx={{ margin: '0 auto', marginBottom: '2rem' }}>
        <br></br>
        <Typography
          variant="body1"
          align="center"
          sx={{
            fontFamily: 'sans-serif, poppins',
            fontWeight: 350,
            fontSize: '24px',
            color: '#00418D',
            '@media (max-width: 768px)': {
              fontSize: '20px',
            },
          }}
        >
           At <span style={{ fontFamily: 'poppins, sans-serif', fontWeight: 350, fontStyle: 'italic' }}>SketchX</span>, we specialize in transforming innovative ideas into cutting-edge solutions. Since 2015, we have partnered with over 100 product companies, delivering bespoke products, advanced machinery, and automation systems tailored to their unique needs—all with unparalleled efficiency and precision.
           <br></br>
           <br></br>
           We see <span style={{ fontFamily: 'sans-serif', fontWeight: 300, fontStyle: 'italic' }}>small and medium enterprises</span> as the unsung heroes of our economy. These businesses are the powerhouse behind job creation and community growth. Our mission? To supercharge these enterprises with top-notch engineering  and innovative technology solutions, helping them thrive and succeed in an ever-evolving market.
           <br></br>
           <br></br>
           <span style={{ fontFamily: 'poppins, sans-serif', fontWeight: 350, fontStyle: 'italic' }}>Design</span> is in our DNA—whether it’s engineering the next big thing or crafting seamless software. At <span style={{ fontFamily: 'poppins, sans-serif', fontWeight: 350, fontStyle: 'italic' }}>SketchX</span>, we don’t just develop technology; we <span style={{ fontFamily: 'poppins, sans-serif', fontWeight: 350, fontStyle: 'italic' }}>Design</span> the future.
        
        </Typography>
        <Button 
          variant="contained" 
          sx={{
            backgroundColor: '#00418D', 
            color: 'white', 
            fontFamily: 'Poppins, sans-serif', 
            marginTop: '2.5rem',
            fontSize: '18px',
            padding: '0.5rem 1.5rem',
            textTransform: 'capitalize'
          }}
          onClick={handleOpen}
        >
          Let’s Connect
        </Button>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <IconButton sx={closeIconStyle} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#00418D' }}
          >
            Let's Talk
          </Typography>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            sx={{ fontFamily: 'Source Serif Pro, serif', fontWeight: 300 }}
          />
          <TextField
            label="Contact Number"
            name="contactNumber"
            variant="outlined"
            fullWidth
            value={formData.contactNumber}
            onChange={handleChange}
            sx={{ fontFamily: 'Source Serif Pro, serif', fontWeight: 300 }}
          />
          <Button 
            variant="contained" 
            fullWidth
            sx={{ backgroundColor: '#00418D', color: 'white', fontFamily: 'Poppins, sans-serif', padding: '1rem 2rem' }}
            onClick={handleSubmit}
          >
            Request a Call Back
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AboutUs;
