// src/sections/ContactUs.js
import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import { db } from '../firebase'; // Import Firestore database
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { logEvent } from 'firebase/analytics'; // Import logEvent for analytics
import { analytics } from '../firebase'; // Import analytics instance

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contactNo: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log the button click event to Google Analytics
    logEvent(analytics, 'button_click', { button_name: 'Send Message' });

    try {
      const docRef = await addDoc(collection(db, 'SketchX_Leads'), formData);
      console.log('Document written with ID: ', docRef.id);
      alert('Message sent successfully!');
      setFormData({
        firstName: '',
        lastName: '',
        contactNo: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Error sending message. Please try again.');
    }
  };

  return (
    <Box id="contact-us" sx={{ padding: '2rem', backgroundColor: 'white', marginBottom: '4rem' }}>
      <br /><br />
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Poppins, sans-serif', // Apply Poppins font for headings
          fontWeight: 500, // Medium weight
          fontSize: '30px',
          color: '#00418D', // Heading color
          '@media (max-width: 768px)': {
            fontSize: '24px', // Adjusted font size for mobile view
          },
        }}
      >
        Contact Us
      </Typography>
      <br /><br />
      <Grid container spacing={4} sx={{ alignItems: 'stretch' }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ marginBottom: '2rem' }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Poppins, sans-serif', // Apply Poppins font for headings
                  fontWeight: '500',
                  color: '#00418D', // Apply color to SKETCHX ENGINEERING OPC PVT LTD
                  fontSize: '18px', // Adjusted font size for larger screens
                  '@media (max-width: 768px)': {
                    fontSize: '16px', // Adjusted font size for mobile view
                  },
                }}
              >
                SKETCHX ENGINEERING OPC PVT LTD
              </Typography>
              <br /><br />
              <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px' }}>ADDRESS : 91SPRINGBOARD,</Typography>
              <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px' }}>1ST FLOOR,KAGALWALA HOUSE,</Typography>
              <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px' }}>KALINA, MUMBAI 400098.</Typography>
              <br /><br />
              <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px' }}>Email: hello@sketchx.in</Typography>
              <br /><br />
              <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px' }}>Contact No: (+91) 98928 35125</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <iframe
                title="Google Map"
                width="100%"
                height="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7922296663946!2d72.86702887381644!3d19.072870552073503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8f21a283513%3A0x45c6ad9e5e90e6f6!2s91springboard%20BKC%20Kalina!5e0!3m2!1sen!2sin!4v1722774763252!5m2!1sen!2sin">
                allowFullScreen=""
                loading="lazy"
              </iframe>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Poppins, sans-serif', // Apply Poppins font for headings
                fontWeight: 500, // Medium weight
                color: '#00418D', // Apply color to Drop Us a Line
                fontSize: '20px', // Adjusted font size for larger screens
                '@media (max-width: 768px)': {
                  fontSize: '18px', // Adjusted font size for mobile view
                },
              }}
              gutterBottom
            >
              Drop Us a Line
            </Typography>
            <form onSubmit={handleSubmit} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                    InputProps={{ style: { fontFamily: 'sans-serif', fontSize: '14px' } }} // Apply sans-serif font and smaller size to input
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                    InputProps={{ style: { fontFamily: 'sans-serif', fontSize: '14px' } }} // Apply sans-serif font and smaller size to input
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Contact No"
                    name="contactNo"
                    variant="outlined"
                    required
                    value={formData.contactNo}
                    onChange={handleChange}
                    InputProps={{ style: { fontFamily: 'sans-serif', fontSize: '14px' } }} // Apply sans-serif font and smaller size to input
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email ID"
                    name="email"
                    variant="outlined"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    InputProps={{ style: { fontFamily: 'sans-serif', fontSize: '14px' } }} // Apply sans-serif font and smaller size to input
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={4}
                    required
                    value={formData.message}
                    onChange={handleChange}
                    InputProps={{ style: { fontFamily: 'sans-serif', fontSize: '14px' } }} // Apply sans-serif font and smaller size to input
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                      maxWidth: { xs: '100%', md: '100%' },
                      padding: '0.75rem 1.5rem',
                      backgroundColor: '#00418D', // Set button background color
                      color: 'white', // Set button text color
                      fontFamily: 'Poppins, sans-serif', // Apply Poppins font to button
                      fontSize: '16px', // Adjusted font size for mobile view
                      textTransform: 'none', // Make text lowercase
                      '&:hover': {
                        backgroundColor: '#00307B', // Darker shade on hover
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
