// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { HashLink as Link } from 'react-router-hash-link'; // Ensure this import is correct

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (section) => {
    setAnchorEl(null);
    navigate(`/#${section}`);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', padding: '0 15px' }}>
      <Toolbar sx={{ justifyContent: 'space-between', position: 'relative', height: '100px' }}>
        <Typography variant="h6">
          <RouterLink to="/">
            <img src="/SketchX.png" alt="Logo" style={{ height: '24px' }} />
          </RouterLink>
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ 
                position: 'absolute', 
                top: '45%', 
                right: '15px', 
                transform: 'translateY(-50%)',
                color: '#00418D' 
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => handleMenuItemClick('about-us')}
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 300,
                  fontSize: '18px',
                  padding: '10px 20px',
                  color: '#00418D', // Ensuring consistent text color
                }}
              >
                <Link to="/#about-us" smooth style={{ textDecoration: 'none', color: 'inherit' }}>
                  About Us
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick('blog')}
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 300,
                  fontSize: '18px',
                  padding: '10px 20px',
                  color: '#00418D', // Ensuring consistent text color
                }}
              >
                <Link to="/#blog" smooth style={{ textDecoration: 'none', color: 'inherit' }}>
                  Blog
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick('contact-us')}
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 300,
                  fontSize: '18px',
                  padding: '10px 20px',
                  color: '#00418D', // Ensuring consistent text color
                }}
              >
                <Link to="/#contact-us" smooth style={{ textDecoration: 'none', color: 'inherit' }}>
                  Contact Us
                </Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" sx={{ margin: '0 20px', fontSize: '18px', fontFamily: 'Poppins, sans-serif' }}>
              <Link to="/#about-us" className="menu-item" smooth style={{ textDecoration: 'none', color: '#00418D' }}>
                About Us
              </Link>
            </Typography>
            <Typography variant="h6" sx={{ margin: '0 20px', fontSize: '18px', fontFamily: 'Poppins, sans-serif' }}>
              <Link to="/#blog" className="menu-item" smooth style={{ textDecoration: 'none', color: '#00418D' }}>
                Blog
              </Link>
            </Typography>
            <Typography variant="h6" sx={{ margin: '0 20px', fontSize: '18px', fontFamily: 'Poppins, sans-serif' }}>
              <Link to="/#contact-us" className="menu-item" smooth style={{ textDecoration: 'none', color: '#00418D' }}>
                Contact Us
              </Link>
            </Typography>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
