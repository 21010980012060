// src/sections/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Box, Typography, Card, styled } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

const blogData = [
  {
    imageUrl: 'blog1.webp',
    link: '/blog/1'
  },
  {
    imageUrl: 'blog2.webp',
    link: '/blog/2'
  },
  {
    imageUrl: 'blog3.webp',
    link: '/blog/3'
  },
];

const CustomDot = styled('div')(({ theme, active }) => ({
  width: active ? '12px' : '8px',
  height: active ? '12px' : '8px',
  backgroundColor: active ? '#00418D' : '#C4C4C4',
  borderRadius: '50%',
  display: 'inline-block',
  margin: '0 4px',
  transition: 'width 0.3s, height 0.3s, background-color 0.3s',
  '&:hover': {
    backgroundColor: '#003366',
    width: '12px',
    height: '12px',
  }
}));

const Blog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    initialSlide: 1,
    customPaging: (i) => <CustomDot />,
    appendDots: (dots) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
        {dots}
      </Box>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          centerPadding: '0px',
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Box id="blog" sx={{ padding: '2rem', backgroundColor: 'white' }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500, // Medium weight
          fontSize: '30px',
          color: '#00418D', // Heading color
          '@media (max-width: 768px)': {
            fontSize: '28px', // Adjusted font size for mobile view
          },
        }}
      >
        Blog
      </Typography>
      <br /><br />
      <Slider {...settings}>
        {blogData.map((post, index) => (
          <Link to={post.link} key={index} style={{ textDecoration: 'none' }}>
            <Box sx={{ padding: '0 0px', cursor: 'pointer' }}>
              <Card sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                <LazyLoadImage
                  src={post.imageUrl}
                  alt={post.title}
                  effect="blur"
                  className="blog-image"
                  style={{
                    width: '90%',
                    height: 'auto',
                    maxHeight: '750px',
                    objectFit: 'cover',
                    margin: '0 auto',
                  }}
                />
                <Typography variant="h6" sx={{ textAlign: 'center', padding: '0rem' }}>
                  {post.title}
                </Typography>
              </Card>
            </Box>
          </Link>
        ))}
      </Slider>
    </Box>
  );
};

export default Blog;
