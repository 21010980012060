// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import FirstRow from './components/FirstRow';
import AboutUs from './sections/AboutUs';
import Blog from './sections/Blog';
import ContactUs from './sections/ContactUs';
import BlogPage from './sections/BlogPage';
import { Box } from '@mui/material';
import Footer from './components/Footer';
import UpButton from './components/UpButton';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase'; // Import the initialized analytics instance

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Track page views
  useEffect(() => {
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location.pathname]);

  const handleBlogClick = (id) => {
    logEvent(analytics, 'blog_click', { blog_id: id });
    navigate(`/blog/${id}`);
  };

  return (
    <Box>
      <Header />
      <FirstRow />
      <AboutUs />
      <Blog handleBlogClick={handleBlogClick} />
      <ContactUs />
      <UpButton />
      <Footer />
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppContent />} />
        <Route path="/blog/:id" element={<BlogPage />} />
      </Routes>
    </Router>
  );
};

export default App;
