// src/components/UpButton.js
import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const UpButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div>
      {isVisible && (
        <Fab
          color="primary"
          aria-label="up"
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            backgroundColor: '#00418D',
            color: 'white',
            '&:hover': {
              backgroundColor: '#00307B',
            },
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </div>
  );
};

export default UpButton;
