// src/components/FirstRow.js
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const textLines = [
  { text: 'Collaborate', image: 'sketch-1.webp' },
  { text: 'Ideate', image: 'sketch-2.webp' },
  { text: 'Innovate', image: 'sketch-3.webp' }
];

const FirstRow = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % textLines.length);
    }, 2000); // Change text and image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container spacing={2} sx={{ padding: '20px', backgroundColor: '#00418D', height: isMobile ? 'auto' : '100vh' }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          color: 'white',
          textAlign: isMobile ? 'center' : 'left',
          padding: isMobile ? '1rem' : '0',
        }}
      >
        {textLines.map((line, index) => (
          <Typography
            key={line.text}
            variant="h3"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              fontStyle: 'italic',
              fontSize: isMobile ? '36px' : '70px',
              color: index === activeIndex ? 'white' : 'grey',
              marginBottom: '10px',
              transition: 'color 0.5s ease'
            }}
          >
            {line.text}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            border: '5px solid #00418D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: isMobile ? '240px' : '100%', // Adjust height for mobile view
            overflow: 'hidden',
            padding: isMobile ? '1rem' : '0', // Padding for mobile view
          }}
        >
          <img
            src={textLines[activeIndex].image}
            alt="Visual Representation"
            style={{
              maxWidth: '100%',
              height: 'auto',
              width: isMobile ? '80%' : '70%',
              transition: 'opacity 0.5s ease',
              objectFit: 'contain', // Ensure image fits within container
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstRow;
