// src/sections/BlogPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, TextField, Button, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UpButton from '../components/UpButton';
import { db } from '../firebase'; // Import the Firebase config
import { collection, addDoc } from 'firebase/firestore';

const blogPosts = {
  '1': {
    title: 'The Future of 3D Printing and Prototyping',
    content: [
      {
        subheading:'From Prototyping to Production',
        paragraphs: [
          '3D printing has evolved beyond rapid prototyping to become a game-changer in manufacturing. Initially, it was used mainly to create prototypes quickly and affordably. Now, it\'s moving into full-scale production, thanks to advancements in materials and printing technology. Industries are leveraging 3D printing to produce end-use parts and products with complex geometries that traditional manufacturing methods can\'t easily achieve.',
        ]
      },
      {
        subheading: 'Customization and Personalization',
        paragraphs: [
          'As 3D printing evolves, customization is becoming a major trend. Consumers are increasingly demanding products tailored to their personal preferences. From bespoke jewelry to custom-fit medical implants, 3D printing offers unparalleled personalization. This shift is enabling industries to cater to individual needs more efficiently, enhancing user satisfaction and driving innovation in consumer products.',
        ]
      },
      {
        subheading: 'On-Demand Manufacturing and Supply Chain Optimization',
        paragraphs: [
          'The rise of on-demand manufacturing is another transformative impact of 3D printing. With the ability to print products as needed, companies can reduce inventory levels and respond more flexibly to market demands. This shift minimizes the need for large warehouses and excess stock, optimizing supply chains and reducing overhead costs. In addition, on-demand production can shorten lead times, enabling faster turnaround for custom orders and emergency replacements.',
        ]
      },
      {
        subheading: 'High-Speed Printing with Klipper Firmware',
        paragraphs: [
          'Speed has always been a critical factor in 3D printing. The introduction of Klipper firmware, which enhances the processing capabilities of 3D printers, is a game-changer. This open-source firmware allows for faster and more precise printing, making it ideal for both industrial applications and hobbyists.',
        ]
      },


      {
        subheading: 'Conclusion',
        paragraphs: [
          'The future of 3D printing and prototyping is transformative. With ongoing advancements in materials and technology, 3D printing is set to redefine manufacturing and design across industries. As we move forward, this innovative technology promises to be a cornerstone of creativity and efficiency, shaping the future of production and personalization.',
        ]
      }
    ]
  },
  '2': {
    title: 'Transforming Digital Products with Design Thinking',
    content: [
      {
        paragraphs: [
          'In the ever-evolving digital landscape, staying ahead of the curve requires more than just technical expertise. It demands a deep understanding of your users and a willingness to innovate. Design Thinking offers a structured approach to achieving this, transforming your digital products into user-centric marvels.',
        ]
      },
      {
        subheading: 'What Makes Design Thinking Unique?',
        paragraphs: [
          'Design Thinking is a human-centered methodology that emphasizes empathy, creativity, and iteration. It’s a systematic approach that guides you through understanding users, defining problems, generating ideas, prototyping solutions, and testing results. Here’s how this approach can elevate your digital products:',
        ]
      },
      {
        subheading: '1. Empathy: The Heart of Design Thinking',
        paragraphs: [
          'The foundation of Design Thinking is empathy. By immersing yourself in your users’ world, you gain invaluable insights into their behaviors, challenges, and desires. For example, if you’re developing an e-commerce platform, understanding how users navigate and shop can reveal pain points in the checkout process or highlight opportunities for personalized recommendations.'
        ]
      },
      {
        subheading: '2. Define the Problem Clearly',
        paragraphs: [
          'Once you’ve gathered user insights, it’s time to define the problem. For instance, for an e-commerce application, if users find the checkout process cumbersome, your problem statement might be: “Users need a streamlined checkout experience to reduce cart abandonment.”',
        ]
      },
      {
        subheading: '3. Ideation: Unlocking Creativity',
        paragraphs: [
          'The ideation phase is where creativity takes center stage. Brainstorm a diverse range of ideas and explore innovative solutions. The goal is to generate as many ideas as possible, without judgment. For example, for the health app, this might involve brainstorming features like visual medication trackers, interactive tutorials, or integration with wearable devices.',
        ]
      },
      {
        subheading: '4. Prototyping: Bringing Ideas to Life',
        paragraphs: [
          'Prototypes are tangible representations of your ideas. They allow you to test and refine concepts quickly. By creating low-fidelity prototypes, you can gather user feedback and make improvements before investing in full-scale development. This approach helps you identify potential issues and optimize your product based on real user input.',
        ]
      },
      {
        subheading: '5. Testing and Iteration: Refining for Success',
        paragraphs: [
          'Testing is a crucial step in the Design Thinking process. By putting prototypes in front of users, you gather valuable feedback and make iterative improvements. This iterative approach ensures that your product evolves to meet user needs effectively. For the health app, testing might reveal that users prefer a daily summary of their medication schedule, leading to enhancements in the app’s reporting features.',
        ]
      },
      {
        subheading: 'Conclusion',
        paragraphs: [
          'Embrace the art and science of Design Thinking, and watch your digital products transform into user-centered masterpieces. It’s a journey that blends creativity with strategic problem-solving, resulting in products that stand out and make a meaningful impact.',
        ]
      },
    ]
  },
  '3': {
    title: 'AI in Manufacturing: Transforming the Future',
    content: [
      {
        subheading: 'Predictive Maintenance',
        paragraphs: [
          'One of the most transformative impacts of AI in manufacturing is in the realm of predictive maintenance. Using advanced AI algorithms, manufacturers can now predict equipment failures before they occur.',
          'This shift from reactive to proactive maintenance minimizes downtime, reduces operational costs, and extends the lifespan of machinery, ensuring that production processes run smoothly and efficiently.'
        ]
      },
      {
        subheading: 'Dynamic Supply Chain Management',
        paragraphs: [
          'AI has also brought significant advancements to supply chain management. With its ability to analyze vast amounts of data, AI helps forecast demand, optimize inventory levels, and streamline logistics. This capability enables manufacturers to respond quickly to market changes, maintain optimal inventory, and reduce waste, thereby significantly improving overall efficiency.'
        ]
      },
      {
        subheading: 'Quality Control and Assurance',
        paragraphs: [
          'In the area of quality control, by analyzing real-time sensor data, AI can detect defects and irregularities instantaneously, ensuring that every product meets the highest quality standards. This real-time monitoring not only enhances product quality but also reduces waste and the costs associated with rework.'
        ]
      },
      {
        subheading: 'Human-Machine Collaboration',
        paragraphs: [
          'Rather than replacing human jobs, AI is proving to be a powerful tool for augmenting human capabilities. Collaborative robots, or cobots, are now working alongside human operators, handling repetitive tasks.'
        ]
      },
      {
        subheading: 'Investment in Talent and Infrastructure',
        paragraphs: [
          'To fully harness the potential of AI, manufacturers must invest in talent development and infrastructure upgrades. Building a workforce skilled in data science, machine learning, and robotics is essential for driving innovation. Upgrading legacy systems and integrating AI-compatible technologies are also crucial steps toward creating a future-ready manufacturing ecosystem.'
        ]
      },
      {
        subheading: 'Conclusion',
        paragraphs: [
          'AI is not just a tool for increasing productivity; it is a catalyst for innovation and sustainability in manufacturing. By embracing AI, manufacturers can unlock new opportunities for growth and competitiveness, ensuring that they remain at the forefront of the industry.'
        ]
      }
    ]
  },
  // Add more blog posts here
};


const BlogPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  // Media queries for responsive text sizes
  const isMobile = useMediaQuery('(max-width:600px)');
  const bodyTextSize = isMobile ? '16px' : '18px';

  const blogPost = blogPosts[id] || { title: 'Not Found', content: [{ subheading: '', paragraphs: ['The blog post you are looking for does not exist.'] }] };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    navigate('/');
  };

  const handleSubscribe = async () => {
    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      // Add email to Firestore
      await addDoc(collection(db, 'Blog_Subscribers'), {
        email,
        timestamp: new Date(),
      });
      alert('Subscribed successfully!');
      setEmail('');
    } catch (error) {
      console.error('Error subscribing:', error);
      alert('There was an error subscribing. Please try again later.');
    }
  };

  return (
    <Box>
      <Header />
      <Box className="blog-page" sx={{ padding: '2rem' }}>
        <IconButton
          onClick={handleBackClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
            backgroundColor: '#00418D',
            color: 'white',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '10px',
            textTransform: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s, transform 0.3s',
            '&:hover': {
              backgroundColor: '#003366',
              transform: 'scale(1.05)',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '28px',
            },
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" className="blog-page-title" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#00418D', marginBottom: '1rem' }}>
          {blogPost.title}
        </Typography>
        {blogPost.content.map((section, index) => (
          <Box key={index} sx={{ marginBottom: '1.5rem' }}>
            <Typography variant="h5" className="blog-page-subheading" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#00418D', marginBottom: '0.5rem' }}>
              {section.subheading}
            </Typography>
            {section.paragraphs.map((paragraph, i) => (
              <Typography key={i} variant="body1" className="blog-page-text" sx={{ fontFamily: 'Source Serif Pro, serif', color: '#464646', fontSize: bodyTextSize, marginBottom: '0.5rem', textIndent: i !== 0 ? '2rem' : '0' }}>
                {paragraph}
              </Typography>
            ))}
          </Box>
        ))}
        <Box className="subscribe-section" sx={{ marginTop: '3rem', padding: '2rem', backgroundColor: '#f5f5f5', borderRadius: '8px', textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#00418D', marginBottom: '1rem' }}>
            Subscribe to our Blog Posts
          </Typography>
          <TextField
            label="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              marginBottom: '1rem',
              width: '100%',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#00418D',
                },
                '&:hover fieldset': {
                  borderColor: '#003366',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#003366',
                },
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubscribe}
            sx={{
              backgroundColor: '#00418D',
              '&:hover': {
                backgroundColor: '#003366',
              },
            }}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
      <UpButton />
      <Footer />
    </Box>
  );
};

export default BlogPage;
