// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#00418D',
        color: 'grey',
        padding: '1rem',
        textAlign: 'center'
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter, sans-serif',
          fontWeight: 550,
          fontStyle: 'italic',
          fontSize: '25px'
        }}
      >
        © SketchX
      </Typography>
    </Box>
  );
};

export default Footer;
